import React from 'react'
import { Spinner } from 'react-bootstrap'
import './Loader.css'

const Loader= ()=>{
    return(
        <section className="wrapper">
            <div className="spinner">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </section>
    )
}
export default Loader
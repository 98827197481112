import React, { useEffect , useState} from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import Meta from '../components/Meta'
import { listExibition } from '../actions/exibitionActions'
import YouTubeIcon from '@mui/icons-material/YouTube';
import { ListGroup,Image,Form,Button,localStorage,Card } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';

const ExibitionScreen = ({ match, history }) => {
    const [value, setValue] = useState('all');
    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const exibitionList = useSelector((state) => state.exibitionList)
  const { loading, error, exibitions, page, pages } = exibitionList

  useEffect(() => {
    dispatch(listExibition(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  
    return(
        <>
        {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
        <Accordion expanded style={{borderRadius: '10px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{color:'grey',fontSize: '18px'}}>Upcomming Exibitions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            exibitions.filter(item => item.category == 'Upcoming').map((exibition)=>{
                return(
                    <div className='exibition-card'>
                        <Col md={8}>
                        <h4>{exibition.name}</h4>
                        <Col>
                        <div>Date: <h5>{exibition.date.split('T')[0]}</h5></div>
                        <div>Time(24hours): <h5>{exibition.date.split('T')[1]}</h5></div>
                        </Col>
                        <div>Place: <h5>{exibition.place}</h5></div>
                        <div>About: <p>{exibition.description}</p></div>
                        {
                          exibition.url ?
                          <Button type='Button' target='_blank' href={exibition.url} className='btn-block E-button' disabled={exibition.link}>
                            Know More
                        </Button>
                        :
                        <></>
                        }
                        {
                          exibition.pdfurl ?
                          <Button type='Button' target='_blank' href={exibition.pdfurl} className='btn-block E-button'>
                            View Pdf
                          </Button>
                          :
                          <></>
                        }
                        {
                          exibition.youtubeurl ? 
                          <Button type='Button' target='_blank' href={exibition.youtubeurl} className='btn-block E-button'><YouTubeIcon /></Button>
                          :
                          <></>
                        }
                        </Col>
                        <Col md={3}>
                        <img width={'100%'} height={'100%'} src={exibition.image} />
                        </Col>
                    </div>
                )
            })
          }
        </AccordionDetails>
      </Accordion>
      <Accordion style={{padding: '10px',borderRadius: '10px',marginBottom: '10px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel2a-header"
        >
          <Typography style={{color:'grey',fontSize: '18px'}}>Previous Exibitions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            exibitions.filter(item => item.category == 'Previous').map((exibition)=>{
                return(
                    <div className='exibition-card'>
                        <Col style={{width: '100%'}}>
                        <h4>{exibition.name}</h4>
                        <Col>
                        <div>Date: <h5>{exibition.date.split('T')[0]}</h5></div>
                        <div>Time(24hours): <h5>{exibition.date.split('T')[1]}</h5></div>
                        </Col>
                        <div>Place: <h5>{exibition.place}</h5></div>
                        <div>About: <p>{exibition.description}</p></div>
                         {
                          exibition.url ?
                          <Button type='Button' target='_blank' href={exibition.url} className='btn-block E-button' disabled={exibition.link}>
                            Know More
                        </Button>
                        :
                        <></>
                        }
                        {
                          exibition.pdfurl ?
                          <Button type='Button' target='_blank' href={exibition.pdfurl} className='btn-block E-button'>
                            View Pdf
                          </Button>
                          :
                          <></>
                        }
                        {
                          exibition.youtubeurl ? 
                          <Button type='Button' target='_blank' href={exibition.youtubeurl} className='btn-block E-button'><YouTubeIcon /></Button>
                          :
                          <></>
                        }
                        </Col>
                        <Col md={4}>
                             <Carousel variant="dark" activeIndex={index} onSelect={handleSelect}>
                              {exibition?.otherImages?.map((item,index) => (
                                  <Carousel.Item>
                                    <img
                                      style={{height: '300px'}}
                                      className="d-block w-100"
                                      src={item}
                                      alt="Images"
                                    />
                                  </Carousel.Item>
                              ))}
                              </Carousel>
                        </Col>
                    </div>
                )
            })
          }
        </AccordionDetails>
      </Accordion>
        </div>
      )
}
        </>
    )
}
export default ExibitionScreen
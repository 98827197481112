import axios from 'axios'
import {
    EXIBITION_LIST_REQUEST,
    EXIBITION_LIST_SUCCESS,
    EXIBITION_LIST_FAIL, 
    EXIBITION_DETAILS_FAIL, 
    EXIBITION_DETAILS_SUCCESS, 
    EXIBITION_DETAILS_REQUEST,
    EXIBITION_DELETE_SUCCESS,
    EXIBITION_DELETE_REQUEST,
    EXIBITION_DELETE_FAIL,
    EXIBITION_CREATE_REQUEST,
    EXIBITION_CREATE_SUCCESS,
    EXIBITION_CREATE_FAIL,
    EXIBITION_UPDATE_REQUEST,
    EXIBITION_UPDATE_SUCCESS,
    EXIBITION_UPDATE_FAIL,
    EXIBITION_CREATE_REVIEW_REQUEST,
    EXIBITION_CREATE_REVIEW_SUCCESS,
    EXIBITION_CREATE_REVIEW_FAIL,
    EXIBITION_TOP_REQUEST,
    EXIBITION_TOP_SUCCESS,
    EXIBITION_TOP_FAIL,
} from '../constants/exibitionConstants'
import { logout } from './userActions'

const link = 'https://vasundaraart-2d92c1da9736.herokuapp.com'

export const listExibition=(keyword='', pageNumber = null)=>async(dispatch)=>{
    try{
        dispatch({type: EXIBITION_LIST_REQUEST})

        const { data } = await axios.get(link+`/api/exibition`)

        dispatch({
            type: EXIBITION_LIST_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        dispatch({
            type: EXIBITION_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const listExibitionDetails=(id)=>async(dispatch)=>{
    try{
        dispatch({type: EXIBITION_DETAILS_REQUEST})

        const { data } = await axios.get(link+`/api/exibition/${id}`)

        dispatch({
            type: EXIBITION_DETAILS_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        dispatch({
            type: EXIBITION_DETAILS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}
export const deleteExibition = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXIBITION_DELETE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      await axios.delete(link+`/api/exibition/${id}`, config)
  
      dispatch({
        type: EXIBITION_DELETE_SUCCESS,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: EXIBITION_DELETE_FAIL,
        payload: message,
      })
    }
  }
  
  export const createExibition = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXIBITION_CREATE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.post(link+`/api/exibition`, {}, config)
  
      dispatch({
        type: EXIBITION_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: EXIBITION_CREATE_FAIL,
        payload: message,
      })
    }
  }
  
  export const updateExibition = (exibition) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXIBITION_UPDATE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.put(
        link+`/api/exibition/${exibition._id}`,
        exibition,
        config
      )
  
      dispatch({
        type: EXIBITION_UPDATE_SUCCESS,
        payload: data,
      })
      dispatch({ type: EXIBITION_DETAILS_SUCCESS, payload: data })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: EXIBITION_UPDATE_FAIL,
        payload: message,
      })
    }
  }
  
  export const createExibitionReview = (exibitionId, review) => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch({
        type: EXIBITION_CREATE_REVIEW_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      await axios.post(link+`/api/exibition/${exibitionId}/reviews`, review, config)
  
      dispatch({
        type: EXIBITION_CREATE_REVIEW_SUCCESS,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: EXIBITION_CREATE_REVIEW_FAIL,
        payload: message,
      })
    }
  }
  
  export const listTopExibitions = () => async (dispatch) => {
    try {
      dispatch({ type: EXIBITION_TOP_REQUEST })
  
      const { data } = await axios.get(link+`/api/exibition/top`)
  
      dispatch({
        type: EXIBITION_TOP_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: EXIBITION_TOP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
  
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import 'date-fns'
import { listExibitionDetails, updateExibition } from '../actions/exibitionActions'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from "date-fns";
import { EXIBITION_UPDATE_RESET } from '../constants/exibitionConstants'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { storage } from '../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import imageCompression from 'browser-image-compression';

const ExibitionEditScreen = ({ match, history }) => {
  const exibitionId = match.params.id

  const [name, setName] = useState('')
  const [place, setPlace] = useState('')
  const [date, setDate] = React.useState(new Date())
  const [image, setImage] = useState('')
  const [otherImages, setOtherImages] = useState([])
  const [videoUrl, setVideoUrl] = useState('')
  const [category, setCategory] = useState('Upcoming')
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)
  const [progresspercentshow, setprogresspercentshow] = useState(false);
  const [progresspercent, setProgresspercent] = useState(0);
  const [pdfurl, setPdfurl] = useState('');
  const [youtubeurl, setYoutubeurl] = useState('');
  const [pdfpercentshow, setPdfpercentshow] = useState(false);
  const [pdfpercent, setPdfpercent] = useState(0);
  const [multiprogresspercentshow, setMultiprogresspercentshow] = useState(false);
  const [multiprogresspercent, setMultiProgresspercent] = useState(0);

  const dispatch = useDispatch()

  const exibitionDetails = useSelector((state) => state.exibitionDetails)
  const { loading, error, exibition } = exibitionDetails

  const exibitionUpdate = useSelector((state) => state.exibitionUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = exibitionUpdate

  const handleDateChange = (date) => {
        setDate(format(date,"yyyy-MM-dd'T'HH:mm"));
    };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: EXIBITION_UPDATE_RESET })
      history.push('/admin/exibitionlist')
    } else {
      if (!exibition.name || exibition._id !== exibitionId) {
        dispatch(listExibitionDetails(exibitionId))
      } else {
        setName(exibition.name)
        setDate(exibition.date)
        setPlace(exibition.place)
        setImage(exibition.image)
        setCategory(exibition.category)
        setDescription(exibition.description)
        setOtherImages(exibition.otherImages)
        setVideoUrl(exibition.url)
        setPdfurl(exibition.pdfurl)
        setYoutubeurl(exibition.youtubeurl)
      }
    }
  }, [dispatch, history, exibitionId, exibition, successUpdate])

  const uploadFileHandler = async (e) => {
    setprogresspercentshow(true)
    const file = e.target.files[0]
    console.log(file);
    if (!file) return;
     const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    const compressedFile = await imageCompression(file, options);
    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); //
    const storageRef = ref(storage, `ArtImage/${compressedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, compressedFile);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setprogresspercentshow(false)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL)
          setprogresspercentshow(false)
          console.log(downloadURL);
        });
      }
    );
  }

  const uploadPDFHandler = async (e) => {
    setPdfpercentshow(true)
    const file = e.target.files[0]
    const storageRef = ref(storage, `Pdfs/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPdfpercent(progress);
      },
      (error) => {
        alert(error);
        setPdfpercentshow(false)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setPdfurl(downloadURL)
          setPdfpercentshow(false)
          console.log(downloadURL);
        });
      }
    );
  }

  const uploadMultipleFileHandler = async(e) =>{
    setMultiprogresspercentshow(true)
    const fileObj = []
    const filearr = []
    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      console.log(fileObj[0][i].name);
      if (!fileObj[0][i]) return;
     const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    const compressedFile = await imageCompression(fileObj[0][i], options);
      const storageRef = ref(storage, `ArtImage/${fileObj[0][i].name}`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setMultiProgresspercent(progress);
        },
        (error) => {
          alert(error);
          setMultiprogresspercentshow(false)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            filearr.push(downloadURL)
            setMultiprogresspercentshow(false)
            console.log(filearr);
          });
        }
      );
  }
  setOtherImages(filearr)
  }


  const submitHandler = (e) => {
    console.log(otherImages);
    e.preventDefault()
    dispatch(
      updateExibition({
        _id: exibitionId,
        name,
        date,
        place,
        image,
        category,
        description,
        otherImages,
        url: videoUrl,
        youtubeurl,
        pdfurl,
      })
    )
  }

  return (
    <>
      <FormContainer>
        <h1>Edit Exibition</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='place'>
              <Form.Label>Place</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter place'
                value={place}
                onChange={(e) => setPlace(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>

              <Form.File
                id='image-file'
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {
                progresspercentshow &&
                <div className='outerbar'>
                  <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                </div>
                }
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label>Upload PDF</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter PDF url'
                value={pdfurl}
                onChange={(e) => setPdfurl(e.target.value)}
              ></Form.Control>

              <Form.File
                id='image-file'
                custom
                onChange={uploadPDFHandler}
              ></Form.File>
              {
                pdfpercentshow &&
                <div className='outerbar'>
                  <div className='innerbar' style={{ width: `${pdfpercent}%` }}>{pdfpercent}%</div>
                </div>
                }
            </Form.Group>

            <MuiPickersUtilsProvider style={{width: '-webkit-fill-available'}} utils={DateFnsUtils}>Date
                <KeyboardDateTimePicker 
                    format="dd/MM/yyyy HH:mm"
                    margin="normal"
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    value={date}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider> 

             <Box style={{margin: '10px 0px'}} sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="category"
                  onChange={(e) => setCategory(e.target.value)}
                  defaultValue={category}
                >
                  <MenuItem value={'Upcoming'}>Upcoming</MenuItem>
                  <MenuItem value={'Previous'}>Previous</MenuItem>
              </Select>
              </FormControl>
            </Box>
            
            <Form.Group controlId='otherimages'>
              <Form.Label>Other Images</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter image url'
                multiple={true}
                value={otherImages}
                onChange={(e) => setOtherImages(e.target.value)}
              ></Form.Control>

              <Form.File
                id='image-file'
                custom
                multiple
                onChange={uploadMultipleFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            {
                multiprogresspercentshow &&
                <div className='outerbar'>
                  <div className='innerbar' style={{ width: `${multiprogresspercent}%` }}>{multiprogresspercent}%</div>
                </div>
                }

            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='Url'>
              <Form.Label>Url</Form.Label>
              <Form.Control
                placeholder='Enter URL'
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='Url'>
              <Form.Label>Youtube Url</Form.Label>
              <Form.Control
                placeholder='Enter Youtube URL'
                value={youtubeurl}
                onChange={(e) => setYoutubeurl(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <br></br>
            <Button className='button' type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ExibitionEditScreen
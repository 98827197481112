import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import {
  listExibition,
  deleteExibition,
  createExibition,
} from '../actions/exibitionActions'
import { EXIBITION_CREATE_RESET } from '../constants/exibitionConstants';
import { getStorage, ref, deleteObject } from "firebase/storage";

const ExibitionListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const exibitionList = useSelector((state) => state.exibitionList)
  const { loading, error, exibitions, page, pages } = exibitionList


  const exibitionDelete = useSelector((state) => state.exibitionDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = exibitionDelete

  const exibitionCreate = useSelector((state) => state.exibitionCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    exibition: createdExibition,
  } = exibitionCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const storage = getStorage();

  useEffect(() => {
    dispatch({ type: EXIBITION_CREATE_RESET })

    console.log(exibitionList);

    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }

    if (successCreate) {
      history.push(`/admin/exibition/${createdExibition._id}/edit`)
    } else {
      dispatch(listExibition('', pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdExibition,
    pageNumber,
  ])

  const deleteHandler = (id,image,pdf) => {
    console.log(image);
    console.log(pdf);
    if (window.confirm('Are you sure')) {
    if(image!='Enter Url'){
        const imageNam = image.split("%2F")
        console.log(imageNam);
        if (imageNam[1]){
        const imageNam1 = imageNam[1].split("?")[0]
        console.log(imageNam1);
        const desertRef = ref(storage, `ArtImage/${imageNam1}`);
        deleteObject(desertRef)
        .catch((error) => {
          alert(error)
        });
        } else {
          dispatch(deleteExibition(id))
          return
        }
      }
    dispatch(deleteExibition(id))
    }
  }

  const   createExibitionHandler = () => {
    dispatch(createExibition())
  }

  return (
    <>
      <Row className='align-items-center'>
          <h1>Exibitions</h1>
          <Button className='mt-3 mb-3 button' onClick={createExibitionHandler}>
            <i className='fas fa-plus'></i> Create Exibition
          </Button>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>CATEGORY</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {exibitions.map((exibition) => (
                <tr key={exibition._id}>
                  <td>{exibition._id}</td>
                  <td>{exibition.name}</td>
                  <td>{exibition.category}</td>
                  <td>
                    <LinkContainer to={`/admin/exibition/${exibition._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(exibition._id,exibition.url,exibition.pdfurl)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={pages} page={page} isAdmin={true} />
        </>
      )}
    </>
  )
}

export default ExibitionListScreen
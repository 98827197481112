import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form } from 'react-bootstrap'
import Rating from '../components/Rating'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productconstants'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Carousel from 'react-bootstrap/Carousel';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate

  useEffect(() => {
    if (successProductReview) {
      setRating(0)
      setComment('')
    }
    if (!product._id || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id))
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
  }, [dispatch, match, successProductReview])

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`)
  }

  const submitHandler = (e) => {
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    )
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={product.name} />
          <Row style={{minheight: '100vh'}}>
            <Col className={'image-container'} md={6}>
              <Image className='image' height="100%" width="100%" src={product.image} alt={product.name} fluid />
            </Col>
            <Col md={6}>
              <ListGroup variant='flush'>
                <ListGroup.Item style={{borderTopRightRadius:'10px',borderTopLeftRadius:'10px'}}>
                  <h3>{product.name}</h3>
                </ListGroup.Item>
                <Card>
                  { false ?
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <strong>$ {product.price}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Status:</Col>
                      <Col>
                        {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Qty</Col>
                        <Col>
                          <Form.Control
                            as='select'
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                    <Button
                      onClick={addToCartHandler}
                      className='btn-block button'
                      type='button'
                      disabled={product.countInStock === 0}
                    >
                      Add To Cart
                    </Button>
                </ListGroup>
                :
                <>
                <Col>To buy the artwork click on the button below</Col>
               <ReactWhatsapp className='whatsappbutton' number="+919632658398" message={`Query on ${product.name}`}><WhatsAppIcon className="fa fa-facebook"/> Chat on WhatsApp</ReactWhatsapp>
                </>
              }
              </Card>
                <p className='headings'>Description:</p>
                <ListGroup.Item>
                  {product.description}
                </ListGroup.Item>
                <p className='headings'>Dimensions:</p>
                <ListGroup.Item>
                  {product.dimensions}
                </ListGroup.Item>
                <p className='headings'>Other Images:</p>
              </ListGroup>
              <Carousel variant="dark" activeIndex={index} onSelect={handleSelect}>
              {product?.otherImages?.map((item,index) => (
                  <Carousel.Item>
                    <img
                      style={{height: '80vh'}}
                      className="d-block w-100"
                      src={item}
                      alt="Images"
                    />
                  </Carousel.Item>
              ))}
              </Carousel>
              {product.video ?
              <Row>
                <p className='headings'>Video:</p>
                <Col style={{display:'flex' ,flexDirection:'row',marginTop:'20px'}}>
                  <iframe src={product.video} allowFullScreen width="100%" height="315"  />
                </Col>
              </Row>
              :
              <></>
              }
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default ProductScreen
import React from 'react';

const TermsandConditions = () =>{
    return(
        <div className='termandconditions'>
            <h1 className='headings'>Terms and Conditions</h1>
            <p>When you buy any products from this website you agree to their Terms & Conditions which can be read here</p>
            <ul>
                <li>
                    Workshop Refund Policy:  No refunds can be given after you have placed the order.
                </li>
                <li>
                    You are not permitted to reproduce, duplicate, copy, sell or otherwise exploit the website or any product image, product listing, product description, price, page layout, page design, trade dress, trademark, logo or other content ("Website Content") for any commercial purpose, except as expressly provided
                </li>
                <li>
                    You are not permitted to use a robot, spider or data mining or extraction tool or process to monitor, extract or copy Website Content (except in the operation or use of internet "search engines," hit counters or similar technology)
                </li>
                <li>
                    You are not permitted to engage in any activity that interferes with the website or another user’s ability to use the website
                </li>
                <li>
                    You are not permitted to modify, create derivative works from, reverse engineer, decompile or disassemble any technology used to provide the website and the services offered on the website
                </li>
                <li>
                    You are not permitted to assist or encourage any third party in engaging in any activity prohibited by these Terms of Use
                </li>
            </ul>
        </div>
    )
}
export default TermsandConditions
import {
    EXIBITION_LIST_REQUEST,
    EXIBITION_LIST_SUCCESS,
    EXIBITION_LIST_FAIL,
    EXIBITION_DETAILS_REQUEST,
    EXIBITION_DETAILS_SUCCESS,
    EXIBITION_DETAILS_FAIL,
    EXIBITION_DELETE_REQUEST,
    EXIBITION_DELETE_SUCCESS,
    EXIBITION_DELETE_FAIL,
    EXIBITION_CREATE_RESET,
    EXIBITION_CREATE_FAIL,
    EXIBITION_CREATE_SUCCESS,
    EXIBITION_CREATE_REQUEST,
    EXIBITION_UPDATE_REQUEST,
    EXIBITION_UPDATE_SUCCESS,
    EXIBITION_UPDATE_FAIL,
    EXIBITION_UPDATE_RESET,
    EXIBITION_CREATE_REVIEW_REQUEST,
    EXIBITION_CREATE_REVIEW_SUCCESS,
    EXIBITION_CREATE_REVIEW_FAIL,
    EXIBITION_CREATE_REVIEW_RESET,
    EXIBITION_TOP_REQUEST,
    EXIBITION_TOP_SUCCESS,
    EXIBITION_TOP_FAIL,
  } from '../constants/exibitionConstants'
  
  export const exibitionListReducer = (state = { exibitions: [] }, action) => {
    switch (action.type) {
      case EXIBITION_LIST_REQUEST:
        return { loading: true, exibitions: [] }
      case EXIBITION_LIST_SUCCESS:
        return {
          loading: false,
          exibitions: action.payload.exibitions,
          pages: action.payload.pages,
          page: action.payload.page,
        }
      case EXIBITION_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  export const exibitionDetailsReducer = (
    state = { exibition: { reviews: [] } },
    action
  ) => {
    switch (action.type) {
      case EXIBITION_DETAILS_REQUEST:
        return { ...state, loading: true }
      case EXIBITION_DETAILS_SUCCESS:
        return { loading: false, exibition: action.payload }
      case EXIBITION_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  export const exibitionDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case EXIBITION_DELETE_REQUEST:
        return { loading: true }
      case EXIBITION_DELETE_SUCCESS:
        return { loading: false, success: true }
      case EXIBITION_DELETE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  export const exibitionCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case EXIBITION_CREATE_REQUEST:
        return { loading: true }
      case EXIBITION_CREATE_SUCCESS:
        return { loading: false, success: true, exibition: action.payload }
      case EXIBITION_CREATE_FAIL:
        return { loading: false, error: action.payload }
      case EXIBITION_CREATE_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const exibitionUpdateReducer = (state = { exibition: {} }, action) => {
    switch (action.type) {
      case EXIBITION_UPDATE_REQUEST:
        return { loading: true }
      case EXIBITION_UPDATE_SUCCESS:
        return { loading: false, success: true, exibition: action.payload }
      case EXIBITION_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case EXIBITION_UPDATE_RESET:
        return { exibition: {} }
      default:
        return state
    }
  }
  
  export const exibitionReviewCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case EXIBITION_CREATE_REVIEW_REQUEST:
        return { loading: true }
      case EXIBITION_CREATE_REVIEW_SUCCESS:
        return { loading: false, success: true }
      case EXIBITION_CREATE_REVIEW_FAIL:
        return { loading: false, error: action.payload }
      case EXIBITION_CREATE_REVIEW_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const exibitionTopRatedReducer = (state = { exibitions: [] }, action) => {
    switch (action.type) {
      case EXIBITION_TOP_REQUEST:
        return { loading: true, exibitions: [] }
      case EXIBITION_TOP_SUCCESS:
        return { loading: false, exibitions: action.payload }
      case EXIBITION_TOP_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
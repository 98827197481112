import axios from "axios"
import { 
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS, 
    USER_LOGIN_FAIL, 
    USER_LOGOUT, 
    USER_REGISTER_SUCCESS, 
    USER_REGISTER_FAIL, 
    USER_REGISTER_REQUEST, 
    USER_DETAILS_SUCCESS, 
    USER_DETAILS_FAIL, 
    USER_DETAILS_REQUEST, 
    USER_UPDATE_PROFILE_REQUEST, 
    USER_UPDATE_PROFILE_SUCCESS, 
    USER_UPDATE_PROFILE_FAIL,
    USER_DETAILS_RESET,
    USER_LIST_FAIL,
    USER_LIST_SUCCESS,
    USER_LIST_REQUEST,
    USER_LIST_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_UPDATE_FAIL,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_REQUEST,
} from "../constants/userConstants"
import { ORDER_LIST_MY_RESET } from '../constants/orderConstants'
import { signInUsingWithEmailAndPassword, registerUsingEmailAndPassword } from "../firebase";

const link = 'https://vasundaraart-2d92c1da9736.herokuapp.com'

export const login = (email, password) => async (dispatch) => {
console.log(email,password);
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const res = await signInUsingWithEmailAndPassword(email,password)
        if (res.emailVerified){
          const { data } = await axios.post(link+'/api/users/login',
            { email, password },
            config)
        dispatch(
            {
                type: USER_LOGIN_SUCCESS,
                payload: data
            })
        
        localStorage.setItem('userInfo', JSON.stringify(data))
        }
        else{
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: 'Email is sent to your mail verify it to login. Note: if you didn\'t recive mail check in Spam once'
        })
        }
    }
    catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })

    }
}
export const logout = ()=> (dispatch)=>{
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('cartItems')
    localStorage.removeItem('shippingAddress')
    localStorage.removeItem('paymentMethod')
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    dispatch({ type: ORDER_LIST_MY_RESET })
    dispatch({ type: USER_LIST_RESET })
    document.location.href = '/login'
}

export const register = (name,email, password) => async (dispatch) => {

    try {
        dispatch({

            type: USER_REGISTER_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const res = await registerUsingEmailAndPassword(email,password)
        const { data } = await axios.post(link+'/api/users',
            { name, email, password, verified: res.emailVerified },
            config)
        dispatch(
            {
                type: USER_REGISTER_SUCCESS,
                payload: data,
            })
            window.location.assign("https://iyengarscreations.com/login")
    }
    catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })

    }
}

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const { userLogin: { userInfo } } = getState()
    console.log(userInfo);
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    
    const { data } = await axios.get(link+`/api/users/${id}`, config)

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const updateUserProfile = (user) => async (dispatch,getState) => {
   
    try {
        dispatch({

            type: USER_UPDATE_PROFILE_REQUEST,
        })
        const {userLogin:{userInfo}}=getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                uthorization:`Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(link+`/api/users/profile`,user,
            config)
        dispatch(
            {
                type: USER_UPDATE_PROFILE_SUCCESS,
                payload: data,
            })
        
    }
    catch (error) {
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })

    }
}
export const listUsers = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(link+`/api/users`, config)
  
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_LIST_FAIL,
        payload: message,
      })
    }
  }
  
  export const deleteUser = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DELETE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      await axios.delete(link+`/api/users/${id}`, config)
  
      dispatch({ type: USER_DELETE_SUCCESS })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_DELETE_FAIL,
        payload: message,
      })
    }
  }
  
  export const updateUser = (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.put(link+`/api/users/${user._id}`, user, config)
  
      dispatch({ type: USER_UPDATE_SUCCESS })
  
      dispatch({ type: USER_DETAILS_SUCCESS, payload: data })
  
      dispatch({ type: USER_DETAILS_RESET })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: message,
      })
    }
  }
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { sendEmailVerification, onAuthStateChanged, GoogleAuthProvider, getAuth, sendSignInLinkToEmail, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAhv8FivOTGgeAJtJ8uWHZmy4FYfmWpymM",
  authDomain: "vasundara-website.firebaseapp.com",
  projectId: "vasundara-website",
  storageBucket: "vasundara-website.appspot.com",
  messagingSenderId: "544991321935",
  appId: "1:544991321935:web:a5ef073c358c76a255af3a",
  measurementId: "G-698PQMPSJR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);

const auth = getAuth(app);

const signInWithGoogle = async () => {
  try{
    const res = await auth.signInWithPopup();
    const user = res.user;
    console.log(user);
    return user
  }
  catch(e){
    throw new Error(e);
  }
};

const signInUsingWithEmailAndPassword = async (email, password) => {
  try{
    const res = await signInWithEmailAndPassword(auth,email, password)
    return res.user
  }
  catch(e){
    throw new Error(e);
  }
};

const registerUsingEmailAndPassword = async (email, password) => {
  try{
    const res = await createUserWithEmailAndPassword(auth, email, password)
    sendEmailVerification(auth.currentUser).then(()=>{
       alert('Email sent to verify your email-Id. Verify It and Login')
    })
    console.log(res.user);
    const user = res.user;
    return user
  }
  catch(e){
    throw new Error(e);
  }
};

const PasswordResetEmail = async (email) => {
  try{
    const res = await sendPasswordResetEmail(email)
    console.log(res);
  }
  catch(e){
    console.log(e);
    throw new Error(e);
  }
  
};

const SignInLinkToEmail = async (email) => {
  try{
    const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'http://localhost:3000/',
        // This must be true.
        handleCodeInApp: true,
        iOS: {
            bundleId: 'com.example.ios'
        },
        android: {
            packageName: 'com.example.android',
            installApp: true,
            minimumVersion: '12'
        },
        dynamicLinkDomain: 'https://www.google.co.in/'
        };
    console.log(email);
    const res = await sendSignInLinkToEmail(auth,email,actionCodeSettings)
    console.log(res);
  }
  catch(e){
    console.log(e);
    throw new Error(e);
  }
  
};

const logout = async () => {
  await auth.signOut()
  console.log('logged out');
};

export {
  auth,
  signInWithGoogle,
  signInWithEmailAndPassword,
  signInUsingWithEmailAndPassword,
  registerUsingEmailAndPassword,
  sendPasswordResetEmail,
  SignInLinkToEmail,
  logout,
};
// h
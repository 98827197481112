import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productconstants'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { storage } from '../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import imageCompression from 'browser-image-compression';

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id

  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)
  const [image, setImage] = useState('')
  const [otherImages, setOtherImages] = useState([])
  const [videoUrl, setVideoUrl] = useState('')
  const [category, setCategory] = useState('')
  const [countInStock, setCountInStock] = useState(0)
  const [description, setDescription] = useState('')
  const [dimensions, setDimensions] = useState('')
  const [uploading, setUploading] = useState(false)
  const [progresspercentshow, setprogresspercentshow] = useState(false);
  const [progresspercent, setProgresspercent] = useState(0);
  const [multiprogresspercentshow, setMultiprogresspercentshow] = useState(false);
  const [multiprogresspercent, setMultiProgresspercent] = useState(0);

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/productlist')
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId))
      } else {
        setName(product.name)
        setPrice(product.price)
        setImage(product.image)
        setOtherImages(product.otherImages)
        setVideoUrl(product.video)
        setCategory(product.category)
        setCountInStock(product.countInStock)
        setDescription(product.description)
        setDimensions(product.dimensions)
      }
    }
  }, [dispatch, history, productId, product, successUpdate])

  const uploadFileHandler = async (e) => {
    // const file = e.target.files[0]
    // console.log(file);
    // const formData = new FormData()
    // formData.append('image', file)
    // setUploading(true)
    // try {
    //   const config = {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   }
    //   const { data } = await axios.post('/api/upload', formData, config)
    //   console.log(data);
    //   setImage(data)
    //   setUploading(false)
    // } catch (error) {
    //   console.error(error)
    //   setUploading(false)
    // }
    setprogresspercentshow(true)
    const file = e.target.files[0]
    console.log(file);
    if (!file) return;
     const options = {
        maxSizeMB: 0.8,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    const compressedFile = await imageCompression(file, options);
    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); //
    const storageRef = ref(storage, `ArtImage/${compressedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, compressedFile);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setprogresspercentshow(false)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL)
          setprogresspercentshow(false)
          console.log(downloadURL);
        });
      }
    );
  }

  const uploadMultipleFileHandler = async(e) =>{
    setMultiprogresspercentshow(true)
    const fileObj = []
    const filearr = []
    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      if (!fileObj[0][i]) return;
     const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    const compressedFile = await imageCompression(fileObj[0][i], options);
    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); //
      const storageRef = ref(storage, `ArtImage/${fileObj[0][i].name}`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setMultiProgresspercent(progress);
        },
        (error) => {
          alert(error);
          setMultiprogresspercentshow(false)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            filearr.push(downloadURL)
            setMultiprogresspercentshow(false)
            console.log(filearr);
          });
        }
      );
  }
  setOtherImages(filearr)
  }

  const submitHandler = (e) => {
    console.log(otherImages);
    e.preventDefault()
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        image,
        otherImages,
        video: videoUrl,
        category,
        description,
        dimensions,
        countInStock,
      })
    )
  }

  return (
    <>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='price'>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter price'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>

              <Form.File
                id='image-file'
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {
                progresspercentshow &&
                <div className='outerbar'>
                  <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                </div>
                }
            </Form.Group>

            <Form.Group controlId='otherimages'>
              <Form.Label>Other Images</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter image url'
                multiple={true}
                value={otherImages}
                onChange={(e) => setOtherImages(e.target.value)}
              ></Form.Control>

              <Form.File
                id='image-file'
                custom
                multiple
                onChange={uploadMultipleFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            {
                multiprogresspercentshow &&
                <div className='outerbar'>
                  <div className='innerbar' style={{ width: `${multiprogresspercent}%` }}>{multiprogresspercent}%</div>
                </div>
                }

            <Form.Group controlId='video Url'>
              <Form.Label>Video Url</Form.Label>
              <Form.Control
                type='video Url'
                placeholder='Enter Video URL'
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='countInStock'>
              <Form.Label>Count In Stock</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter countInStock'
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              ></Form.Control>
            </Form.Group>

             <Box style={{margin: '10px 0px'}} sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="category"
                  onChange={(e) => setCategory(e.target.value)}
                  defaultValue={null}
                >
                  <MenuItem value={'Mixed Media'}>Mixed Media</MenuItem>
                  <MenuItem value={'Water Colors'}>Water Colors</MenuItem>
                  <MenuItem value={'Acrylic Painting'}>Acrylic Painting</MenuItem>
                  <MenuItem value={'Oil Painting'}>Oil Painting</MenuItem>
                  <MenuItem value={'Pencil Colors'}>Pencil Colors</MenuItem>
                  <MenuItem value={'Pastel Colors'}>Pastel Colors</MenuItem>
              </Select>
              </FormControl>
            </Box>

            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='dimensions'>
              <Form.Label>Dimensions</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter dimensions'
                value={dimensions}
                onChange={(e) => setDimensions(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <br></br>
            <Button className='button' type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProductEditScreen
import React from 'react'
import { Link,useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import Rating from './Rating'
import { Button } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';

const Product = ({ product }) => {
    const history = useHistory()
    const addToCartHandler = (id) => {
    history.push(`/cart/${id}?qty=1`)
  }
    return (
        <Card style={{borderRadius:'8px'}} className='my-3 cardd'>
            <div className='card-img-center'>
            <Link to={`/product/${product._id}`}>
                <Card.Img style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px'}} width={'100%'} src={product.image} varient='top' />
            </Link>
            </div>
            <Card.Body>
                <Link to={`/product/${product._id}`}>
                    <Card.Title as='div'>
                        <strong>{product.name}</strong>
                    </Card.Title>
                
                <Card.Text as='div'>
                    {product.category}
                </Card.Text>
                {
                    false ? 
                    <Card.Text as='h3'> $ {product.price}</Card.Text>
                    :
                    <Card.Text as='h3'></Card.Text>
                }
                </Link>
                {
                    false ?
                    <Button type='Button' className='btn-block button' onClick={()=>addToCartHandler(product._id)}><i className='fas fa-shopping-cart'></i></Button>
                    :
                    <ReactWhatsapp className='whatsappbutton' number="+628121115562" message={`Query on ${product.name}`}><WhatsAppIcon className="fa fa-facebook"/></ReactWhatsapp>
                }
            </Card.Body>
        </Card >
    )
}

export default Product

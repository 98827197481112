import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { LinkContainer } from 'react-router-bootstrap'
import { Nav } from 'react-bootstrap'
import logo from '../assets/logo.png' 
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';

const Footer = () => {
    return (
                <div className='footer'>
                    <LinkContainer exact to='/'>
                        <Nav.Link>
                            <img width={'150px'} style={{marginBottom: '16px'}} src={logo} />
                        </Nav.Link>
                    </LinkContainer>
                    <ul className="text-center wig d-flex justify-content-center">
                        <li><a href="mailto: " rel="noreferrer" target="_blank"><MailOutlineIcon className="fa fa-twitter"/></a></li>
                        <li><a href="https://www.instagram.com/iyengarscreation/" rel="noreferrer" target="_blank"><InstagramIcon className="fa fa-instagram"/></a></li>
                        <li><a href="https://www.facebook.com/vasundara.sur" rel="noreferrer" target="_blank"><FacebookOutlinedIcon className="fa fa-facebook"/></a></li>
                        <li><a href="https://www.tiktok.com/@vasundaraiyengar?_t=8VFWzKmMXQX&_r=1" rel="noreferrer" target="_blank"><AudiotrackIcon className="fa fa-linkedin"/></a></li>
                        <li><ReactWhatsapp style={{background: 'black',padding: '0px',border: '0px'}} number="+628121115562" message="Hello!"><a><WhatsAppIcon className="fa fa-facebook"/></a></ReactWhatsapp></li>
                    </ul>
                    <div>
                        <p>&copy;Copyrights Vasundara sur 2022 |&nbsp;<LinkContainer to="/termsandconditions"><p>Terms and Conditions</p></LinkContainer></p>
                    </div>
                </div>
    )
}

export default Footer

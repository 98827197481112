import React, { useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import profile from '../assets/aboutprofile.png'
import Button from '@mui/material/Button';
import Footer from '../components/Footer';
import Message from '../components/Message'
import { useDispatch, useSelector } from 'react-redux';
import { listTopProducts } from '../actions/productActions';
import Loader from '../components/Loader';
import { Col, Container, Row } from 'react-bootstrap';
import Product from '../components/Product';
import logo from '../assets/icon.png';
import Aos from 'aos';
import 'aos/dist/aos.css';

const HomeScreen = (props) =>{
  const productTopRated = useSelector((state) => state.productTopRated)
  const { loading, error, products, } = productTopRated
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(listTopProducts())
    Aos.init({ duration: 800 })
  },[])

  console.log(productTopRated);

  return(
    loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
    <div className="home-screen">
      <div className="landing-view">
        <div style={{width:'50vw'}}></div>
        <div className="writing">
          <div data-aos="fade-left">
          <p>
            <h1>
            Hey, I'am Vasundara Sur
          </h1>
            <span>"</span>There is no greater reward than seeing your vision in the form of artwork. Message and mood can be conveyed through colour, texture, structure and shape. A visually appealing art is not always an immediate product of visualisation and planning. There is a constant conflict and dialogue with the inner artist during which ideas and schemes may be changed. As an artist, I enjoy the process more than the outcome, whether mixed media, acrylic, watercolour or pastel. Over time I have learnt to convey my story through impressionistic.<span>"</span>
          </p>
          </div>
        </div>
       </div>
      <Container>
        <div style={{display: 'flex',}}><img className='logo' src={logo} /><h6 className='headings'><div data-aos="fade-right">My Latest Art work</div></h6></div>
        <Row>
        {
          products.map((product)=>{
            return(
                <Col key={product._id} className={'card-length'} sm={12} md={6} lg={4} xl={3}>
                    <Product product={product}/>
                </Col>
            )
          })
        }
        </Row>
      </Container>
      <div className='aboutme'>
        <div className='profile'>
          <img width='fit-content' src={profile} />
        </div>
        <div className='about-text-section'>
          <div style={{display: 'flex',alignItems: 'center'}}><img className='logo' src={logo} /><h1 className='headings-aboutme'><div data-aos="fade-left">About Me!</div></h1></div>
          <div data-aos="fade-right"><p>I am passionate about artworks that involve colours and textures. My first inspiration was my father, M. S. Gopal, whose quick and crispy sketches always fascinated me. My love for art always existed as I was constantly exposed to intricate geometric designs my mother and grandmother drew in the form of rangoli in front of the house. You can see my love for bright colours, contemporary touch of Indian values and my constant hunger to learn new techniques. I am fortunate to have worked under the guidance of Master artists like Rafique Ahmed from Bangladesh, Milind Mulick from India and Pak Agus Budianto from Indonesia, who have all played a vital role in refining my artistic abilities.

            <br/><br/>I have participated in many worldwide exhibitions representing the International Watercolor Society, Indonesia. Art is my hobby and passion, which I pursue in my free time.

            <br /><br/>I am from India currently residing in Indonesia with my husband Sandeep Sur. I am blessed with a son and a daughter who constantly critique my artwork and give me valuable feedback and encourage me to be who I am .
          </p></div>
          <div data-aos="zoom-in-up"><a className="asp-embed-link" rel='noreferrer' href="https://express.adobe.com/page/e1Gp3nD2jeDa4/" target="_blank"><Button type='Button' className='btn-block button'>Know More about me!</Button></a></div>
        </div>
      </div>
      <div className="art-exibition">
        <div className='about-text-section'>
            <div style={{display: 'flex'}}><img className='logo' src={logo} /><h1 className='headings-aboutme'><div data-aos="fade-right">Art Exhibitions</div></h1></div>
            <div data-aos="fade-right"><p>As a part of the International water color society and Indoindian community, I have participated in  watercolor exhibitions around the world. Here is the list of some of the exhibitions I have participated in.</p></div>
            <div data-aos="zoom-in-up"><a className="asp-embed-link" rel='noreferrer' href="https://express.adobe.com/page/dOGcgnLUOZYOW/" target="_blank"><Button type='Button' className='btn-block button'>Check Out my Art Exhibitions!</Button></a></div>
        </div>
        <div className='profile'>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/ThnMlrnyIvQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    )
  );
};


export default HomeScreen;